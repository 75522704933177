<template>
  <the-new-header/>
  <div class="page-content dashboard-page">
    <slot name="sidebar"></slot>
    <main :class="[{'collapsed': sidebarIsCollapsed}, {'full-width': isMobile}]">
      <!--      <the-header>-->
      <!--        <template v-if="!isMobile"-->
      <!--                  v-slot:header-left>-->
      <!--          <h1 class="page-title">-->
      <!--            Dashboard-->
      <!--          </h1>-->
      <!--        </template>-->
      <!--      </the-header>-->
      <div class="new-container">
        <div class="add-entity-block-header dashboard-header-block mobile-hidden">
          <h1 class="page-title">Dashboard</h1>
          <order-radaso-button :pre-selected="['aso-audit', 'launch-support', 'full-aso-support']" />
        </div>
      </div>
      <div class="new-container">
        <div class="add-entity-block-header dashboard-header-block mobile-visible">
          <h1 class="page-title">Dashboard</h1>
          <order-radaso-button :pre-selected="['aso-audit', 'launch-support', 'full-aso-support']" />
        </div>

        <header-tabs>
          <div class="tabs-content">
            <router-link class="tab-item"
                         tag="div"
                         :to="{name: 'DashboardOverview'}">
              <span class="text">Overview</span>
            </router-link>

            <router-link class="tab-item"
                         tag="div"
                         :to="{name: 'DashboardVisibility'}">
              <span class="text">Search Visibility</span>
            </router-link>

            <router-link class="tab-item"
                         tag="div"
                         :to="{name: 'DashboardCategoryRanking'}">
              <span class="text">Category Ranking</span>
            </router-link>

            <router-link class="tab-item"
                         tag="div"
                         :to="{name: 'DashboardAppInfo'}">
              <span class="text">App Info</span>
            </router-link>
          </div>
        </header-tabs>

        <template v-if="isMobile">
          <header-tabs @dropdown-closed="tabDropdownOpened = false">
            <div class="mobile-tabs-content"
                 :class="{active: tabDropdownOpened}">
              <div class="mobile-tabs-heading">
                <div class="active-tab"
                     @click="tabDropdownOpened = !tabDropdownOpened">
                  <span class="text">{{ route?.meta?.pageTitle }}</span>
                  <div class="tab-icon">
                    <img src="@/assets/svg/arrow.svg" alt="Arrow icon">
                  </div>
                </div>
              </div>
              <div class="mobile-tabs-list">
                <router-link class="tab-item"
                             tag="div"
                             :to="{name: 'DashboardOverview'}"
                             @click="tabDropdownOpened = !tabDropdownOpened">
                  <div class="text">Overview</div>
                </router-link>

                <router-link class="tab-item"
                             tag="div"
                             :to="{name: 'DashboardVisibility'}"
                             @click="tabDropdownOpened = !tabDropdownOpened">
                  <div class="text">Search Visibility</div>
                </router-link>

                <router-link class="tab-item"
                             tag="div"
                             :to="{name: 'DashboardCategoryRanking'}"
                             @click="tabDropdownOpened = !tabDropdownOpened">
                  <div class="text">Category Ranking</div>
                </router-link>

                <router-link class="tab-item"
                             tag="div"
                             :to="{name: 'DashboardAppInfo'}"
                             @click="tabDropdownOpened = !tabDropdownOpened">
                  <div class="text">App Info</div>
                </router-link>
              </div>
            </div>
          </header-tabs>
        </template>

        <app-info-top-block :top-data-is-loaded="topDataIsLoaded"
                            :top-app-data="topAppData"/>
      </div>

      <router-view v-slot="slotProps">
        <transition name="route" mode="out-in">
          <div class="new-container">
            <component :is="slotProps.Component"></component>
          </div>
        </transition>
      </router-view>

    </main>
  </div>
</template>

<script>
import {useRouter, useRoute} from 'vue-router';
import {useStore} from "vuex";
import TheHeader from '@/components/TheHeader';
import {httpRequest} from "@/api";
import {ref, onMounted, getCurrentInstance, computed, watch} from 'vue';
import {getCookie} from "@/utils/utils";
import AppInfoTopBlock from "@/views/DashboardPage/components/AppInfoTopBlock";
import OrderRadasoButton from "@/components/OrderRadaso/Button/index.vue";

export default {
  name: 'MetadataKeywords',
  components: {
    OrderRadasoButton,
    TheHeader,
    AppInfoTopBlock,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const {proxy} = getCurrentInstance();
    let currentAppId = getCookie('currentAppId');
    const apiUrls = proxy?.$ApiUrls;
    const store = useStore();

    let title = ref('');
    let loaderText = ref('');
    let tabDropdownOpened = ref(false);
    let currentKeywordCountryCode = ref('US');
    let currentComponent = ref('overview');
    let topDataIsLoaded = ref(false);
    let topAppData = ref({
      currentApp: {},
      app: {},
      score: {
        text: 0,
        graphic: 0,
        reviews: 0,
        visibility: 0,
      },
    });

    const isMobile = computed(() => store.getters.isMobile);
    const sidebarIsCollapsed = computed(() => store.getters.sidebarIsCollapsed);
    const currentApp = computed(() => store.getters.currentApp);
    const currentCountryManual = computed(() => store.getters.currentCountryManual);
    const currentCountry = computed(() => store.getters.currentCountry);

    currentKeywordCountryCode.value = currentCountry.value?.code;
    let url = '?country_code=' + currentKeywordCountryCode.value + '&app_id=' + currentAppId;

    function cleanLocalStorageItems() {
      localStorage.removeItem('trackingKeywordsDataLocalStorage');
      localStorage.removeItem('trackingKeywordsShortenedDataLocalStorage');
      localStorage.removeItem('competitorsDataLocalStorage');
      localStorage.removeItem('compareCategoryDataTop10DataLocalStorage');
      localStorage.removeItem('compareCategoryDataTop50DataLocalStorage');
      localStorage.removeItem('searchVisibilityDataLocalStorage');
      localStorage.removeItem('categoryVisibilityDataLocalStorage');
    }

    function tabClickHandler(name) {
      currentComponent.value = name;
    }

    async function fetchTopAppData(url, mode = 'default', dataObj) {
      topDataIsLoaded.value = false;

      topAppData.value = {};

      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.dashboard.TOP_APP_DATA + url);

      let tempValue;

      if (mode === 'default') {
        tempValue = {
          app: {...result?.app},
          score: {...result?.score},
          currentApp: {...currentApp.value},
        }
      } else {
        tempValue = {
          app: {...result?.app},
          currentApp: {...currentApp.value},
          score: {...await fetchAsoScoreData(dataObj)},
        }
      }

      topAppData.value = {...tempValue};

      topDataIsLoaded.value = true;
    }

    async function fetchAsoScoreData(dataObj) {
      let url = dataObj?.appId + '/' + dataObj?.countryCode + '/aso-score';
      const result = await httpRequest('GET', process.env.VUE_APP_API_URL + apiUrls.dashboard.ASO_SCORE_DATA + url);

      if (result) {
        return result;
      }

      return [];
    }

    watch([currentCountry, currentApp, currentCountryManual], async function (newValues, oldValues) {
      if (JSON.stringify(newValues[0]) !== JSON.stringify(oldValues[0])) {
        url = '?country_code=' + newValues[0]?.code + '&app_id=' + currentAppId;
      }

      if (newValues[1]?.id !== oldValues[1]?.id && oldValues[1]?.id) {
        currentAppId = newValues[1]?.id;
        url = '?country_code=' + newValues[0]?.code + '&app_id=' + newValues[1]?.id;
        currentKeywordCountryCode.value = newValues[0]?.code;

        await fetchTopAppData(url);

        // cleanLocalStorageItems();
        // router.go(0);
      }

      if (JSON.stringify(newValues[2]) !== JSON.stringify(oldValues[2])) {
        url = '?country_code=' + newValues[2]?.code + '&app_id=' + currentAppId;
        currentKeywordCountryCode.value = newValues[0]?.code;

        cleanLocalStorageItems();
        await fetchTopAppData(url, 'no-score-update', {countryCode: newValues[2]?.code, appId: currentAppId});
      }
    });

    onMounted(async function () {
      await fetchTopAppData(url);

      cleanLocalStorageItems();
    });

    return {
      title,
      loaderText,
      isMobile,
      sidebarIsCollapsed,
      currentComponent,
      currentKeywordCountryCode,
      currentApp,
      tabClickHandler,
      topAppData,
      topDataIsLoaded,
      tabDropdownOpened,
      route,
    }
  },
}
</script>

<style lang="scss" src="./styles.scss"></style>
